<template>
  <div class="p-2">
    <header-slot>
      <template #actions>
        <b-row class="d-flex justify-content-end">
          <!-- <b-col cols="12">
            <div
              class="d-flex justify-content-xl-end align-items-end justify-content-start"
            >
              <b-button variant="primary" @click="showMedicalRest = true" class="customized-button"
              style="background: #00D9D1 !important;"
              >
             
              <span>
                Medical rest
              </span>
              </b-button>
              <b-button
                class="mr-1 ml-1 customized-button"
                variant="success"
                @click="openHoursJustifiedModal()"
                style="background: #7ED957 !important;"
              >
             
              <span>Justified Hours</span>
                
              </b-button>
              <b-button
              class="customized-button"
                variant="danger"
                @click="openRejectedJustificationsModal()"
                style="background: #FF6D6D !important;"
              >
             
               <span>Rejected Hours</span>
              </b-button>
              <b-button
                variant="info"
                class="ml-1 customized-button"
                @click="openModalPayrollPayments = true"
                style="background: #8F5FE8 !important;"
              >

              <span>Payroll Payments</span>
              </b-button>
            </div>
          </b-col> -->

          <b-col>
            <div>
              <b-button class="customized-button" :to="currentUser.user_id==1?'/home':'/dashboard'"
              :style="isDarkSkin ? 'background-color: #17171A !important;' : 'background-color: #fff !important; color: #000 !important;'"
              >
                <span><feather-icon icon="HomeIcon" size="25"/></span>
              </b-button>
            </div>
          </b-col>


        </b-row>
      </template>
    </header-slot>

    <b-card :style="isDarkSkin ? 'background: #17171a': 'background: #fff'" style="border-radius: 16px">
      <b-row class="d-flex align-items-center justify-content-start">
        <b-col cols="12" md="6">
      <b-card :style="isDarkSkin ? 'background: #292c33' : 'background: #F6F6FE'">
        <b-row class="justify-content-center">
          <b-col class="align-items-center text-center flex-wrap d-flex justify-content-center">
            <div class="mx-1">
              <h3 class="text-goals d-flex align-items-center">
                Goals Hours:
                <span class="dataStyle mx-1" style="color: #00d25b; font-size: 32px; font-weight: 600">{{ hoursWorkedByFilter }}</span>
                <span class="dataStyle" style="color: #0090e7; font-size: 32px"> - </span>
              </h3>
            </div>
          
            <div class="mx-1">
              <h3 class="text-goals d-flex align-items-center">
                Hours Worked:
                <span class="dataStyle mx-1" style="color: #8f5fe8; font-size: 32px; font-weight: 600">{{ otherCalculations.accumulatedHoursByFilter }}</span>
                <span class="dataStyle" style="color: #0090e7; font-size: 32px"> = </span>
              </h3>
            </div>
            <div>
              <h3 class="text-goals d-flex align-items-center">
                Missing Hours:
                <span class="dataStyle mx-1" style="color: #fc424a; font-size: 32px; font-weight: 600">
                  {{ hoursWorkedByFilter - otherCalculations.accumulatedHoursByFilter }}
                </span>
              </h3>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

        <!-- options buttons -->
        <b-col cols="12" md="6">
      <b-card>
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <b-button class="customized-button" @click="showMedicalRest = true"  style="background: #00D9D1 !important;">
            <span class="btn-text">Medical rest</span>
          </b-button>
          <b-button class="customized-button" @click="openHoursJustifiedModal()" style="background: #7ED957 !important;">
            <span class="btn-text">Justified Hours</span>
          </b-button>
          <b-button class="customized-button" @click="openRejectedJustificationsModal()" style="background: #FF6D6D !important;">
            <span class="btn-text">Rejected Hours</span>
          </b-button>
          <b-button class="customized-button" @click="openModalPayrollPayments = true" style="background: #8F5FE8 !important;">
            <span class="btn-text">Payroll Payments</span>
          </b-button>
        </div>
      </b-card>
    </b-col>
      </b-row>

      <!-- separator -->
      <hr style="border: 1.5px solid #0090e7" />

      <b-row>
        <b-col cols="12" md="2" class="d-flex justify-content-center align-items-center">
          <!-- selector grafic | report -->
          <customized-radio-group
            id="type-filter"
            style="margin-bottom: 0px"
            :options="firstFilter.options"
            :selected-option="'report'"
            class="filter-item"
            @changeOption="(data) => { firstFilter.value = data; }"
          />
        </b-col>

        <!-- division -->
        <div class="miDiv" style="height: auto; border: 1.5px solid #0090e7" />
        
        <b-col cols="12" md="6">

          <b-row class="d-flex justify-content-center">
            <div class="d-flex justify-content-center">

              <!-- selector month | week | customized -->
              <customized-radio-group
                id="filter-by"
                style="margin-bottom: 0px"
                :options="secondFilter.options"
                :selected-option="'week'"
                class="filter-item"
                @changeOption="
                  (data) => {
                    secondFilter.value = data;
                  }
                "
              />
            </div>

            <!-- paginator week -->
            <div
              v-if="secondFilter.value == 'week'"
              class="d-flex justify-content-center"
            >
              <div class="d-flex justify-content-center">
                <div>
                  <b-button
                    style="height: 44px; width: 44px"
                    class="btn-icon border-radius-left"
                    @click="changeWeek(-1)"
                  >
                    <feather-icon icon="ChevronLeftIcon" />
                  </b-button>
                </div>
                <span
                  style="
                    font-size: 16px;
                    padding: 0 20px 0 20px;
                    height: 44px;
                  "
                  :style="isDarkSkin? 'background: #292c33' : 'background: #ffffff'"
                  class="d-flex justify-content-center align-items-center text-center"
                  >{{ paramsWeek.from | myGlobal }} -
                  {{ paramsWeek.to | myGlobal }}</span
                >
                <div>
                  <b-button
                    style="height: 44px; width: 44px"
                    class="btn-icon border-radius-right"
                    :disabled="disabledWeek"
                    @click="changeWeek(1)"
                  >
                    <feather-icon icon="ChevronRightIcon" />
                  </b-button>
                </div>
              </div>
            </div>

            <!-- selectors month -->
            <div
              v-if="secondFilter.value == 'month'"
              class="d-flex justify-content-center align-items-center"
            >
              <b-form-group
                class="mr-1 d-flex justify-content-center align-items-center"
              >
                <select
                  id="months"
                  v-model="paramsMonth.month"
                  name="months"
                  class="text-montserrat select-dark pl-1 pr-2"
                  :style="isDarkSkin? 'background: #292C33;' : 'border: 1px solid #BDBDBD !important; background: #fff; color: #18173D'"
                >
                  <option
                    v-for="month in optionMonths"
                    :key="month.value"
                    :value="month.value"
                  >
                    {{ month.text }}
                  </option>
                </select>
              </b-form-group>
              <b-form-group>
                <select
                  id="years"
                  v-model="paramsMonth.year"
                  name="years"
                  class="text-montserrat select-dark pl-1 pr-2"
                  :style="isDarkSkin? 'background: #292C33;' : 'border: 1px solid #BDBDBD !important; background: #fff; color: #18173D'"
                >
                  <option
                    v-for="year in optionsYear"
                    :key="year.value"
                    :value="year.value"
                  >
                    {{ year.value }}
                  </option>
                </select>
              </b-form-group>
            </div>

            <!-- customized selectors -->
            <div
              v-if="secondFilter.value == 'customized'"
              class="d-flex justify-content-center align-items-center"
            >
              <p
                style="margin: 0px; font-size: 16px"
                class="miDiv text-montserrat mr-1"
              >
                Start Date
              </p>
              <b-form-group
                style="margin: 0px; border: none"
                label-for="start_day"
                class="mr-1"
              >
                <kendo-datepicker
                  id="start_day"
                  v-model="paramsWeek.from"
                  v-mask="'##/##/####'"
                  style="
                    border-style: hidden !important;
                    width: 153px !important;
                    height: 44px !important;
                    border-radius: 7px !important;
                    font-size: 16px !important;
                    color: #a09fa5;
                  "
                  :format="'MM/dd/yyyy'"
                />
              </b-form-group>
              <p
                style="margin: 0px; font-size: 16px"
                class="miDiv text-montserrat mr-1"
              >
                End date
              </p>
              <b-form-group label-for="end_day" style="margin: 0px">
                <kendo-datepicker
                  id="end_day"
                  v-model="paramsWeek.to"
                  v-mask="'##/##/####'"
                  style="
                    border-style: hidden !important;
                    width: 153px !important;
                    height: 44px !important;
                    border-radius: 7px !important;
                    font-size: 16px !important;
                    color: #a09fa5;
                  "
                  :format="'MM/dd/yyyy'"
                />
              </b-form-group>
            </div>
          </b-row>
        </b-col>
        <div class="miDiv" style="height: auto; border: 1.5px solid #0090e7" />
        <b-col cols="12" md="3"> </b-col>
      </b-row>

      <hr class="miDiv" style="border: 1.5px solid #0090e7" />

      <!-- report section -->

      <b-row v-if="firstFilter.value == 'report'">
        <b-col cols="12">
          <b-card>
            <b-card-text class="overflow-auto">
              <!-- TABLE FROM ATTENDANCE OF WEEK-->
              <grid-details-marks
                v-if="
                  secondFilter.value == 'week' ||
                  secondFilter.value == 'customized'
                "
                :key="keyGrid"
                :filters="{
                  userId: this.currentUser.user_id,
                  day: null,
                  weekStart: paramsWeek.from,
                  weekEnd: paramsWeek.to,
                  month: paramsMonth.month,
                  year: paramsMonth.year,
                }"
                :type-dashboard="'dashboardUser'"
                @hoursAcumulated="hoursAccumulated"
              />
              <!-- TABLE FROM ATTENDANCE OF MONTH-->
              <grid-months-marks
                v-else
                :key="keyGridMonth"
                :filters="{
                  userId: this.currentUser.user_id,
                  month: paramsMonth.month,
                  year: paramsMonth.year,
                }"
                :type-dashboard="'dashboardUser'"
                @hoursAcumulated="hoursAccumulated"
              />

              
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-else>
          <b-col cols="12">
            <b-card>
              <b-card-text>
                <!-- GRAPHICS FROM ATTENDANCE -->
                <vue-apex-charts
                  :key="keyChart"
                  type="bar"
                  height="500"
                  :options="chartOptions"
                  :series="series"
                />
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>

      <hr style="border: 1.5px solid #0090e7" />

      <b-row>
        <b-col cols="12" md="6">

          <!-- Day marks section -->
            <b-card>
              <h1 class="mb-2 target-title">Day marks</h1>
              <p class="target-p mb-4">
                {{ currentDate }}
              </p>

              <b-col>
                <template v-if="dayOffWeek < 7">
                  <b-row class="mb-2">
                    <!-- work start -->
                    <b-col>
                      <b-row>
                        <label
                          class="d-flex justify-content-center align-items-center icon-card mr-2"
                          :style="isDarkSkin ? 'background: #1c3238 !important' : 'background: #E8FFF3 !important'"
                        >
                          <img
                            :src="require('@/views/assistance/assets/start-day.svg')"
                            alt=""
                          />
                        </label>
                        <div
                          class="d-flex justify-content-center align-items-center mr-2"
                        >
                          <label style="color: #a09fa5" class="font-p"
                            >Work start</label
                          >
                        </div>
                        <div
                          class="d-flex justify-content-end align-items-center"
                        >
                          <label style="color: #0090e7" class="font-h">{{
                            dailyMarkings.start | myFullTime
                          }}</label>
                        </div>
                      </b-row>
                    </b-col>
                    <!-- work end -->
                    <b-col>
                      <b-row>
                        <label
                          class="d-flex justify-content-center align-items-center icon-card mr-2"
                          :style="isDarkSkin ? 'background: #3a2434 !important' : 'background: #FFF5F8 !important'"
                        >
                          <img
                            :src="
                              require('@/views/assistance/assets/end-day.svg')
                            "
                            alt=""
                          />
                        </label>
                        <div
                          class="d-flex justify-content-center align-items-center mr-2"
                        >
                          <label style="color: #a09fa5" class="font-p"
                            >Work end</label
                          >
                        </div>
                        <div
                          class="d-flex justify-content-end align-items-center"
                        >
                          <label style="color: #0090e7" class="font-h">{{
                            dailyMarkings.end == dailyMarkings.start
                              ? null
                              : dailyMarkings.end | myFullTime
                          }}</label>
                        </div>
                      </b-row>
                    </b-col>
                  </b-row>
                </template>

                <template v-else>
                  <b-row class="my-2">
                    <!-- work start -->
                    <b-col>
                      <b-row>
                        <label
                          class="d-flex justify-content-center align-items-center icon-card mr-2"
                          style="background: #1c3238"
                          :style="isDarkSkin ? 'background: #1c3238 !important' : 'background: #E8FFF3 !important'"

                        >
                          <img
                            :src="
                              require('@/views/assistance/assets/start-day.svg')
                            "
                            alt=""
                          />
                        </label>
                        <div
                          class="d-flex justify-content-center align-items-center mr-2"
                        >
                          <label style="color: #a09fa5" class="font-p"
                            >Work start</label
                          >
                        </div>
                        <div
                          class="d-flex justify-content-end align-items-center"
                        >
                          <label style="color: #0090e7" class="font-h">{{
                            dailyMarkings.start | myFullTime
                          }}</label>
                        </div>
                      </b-row>
                    </b-col>
                    <!-- end break -->
                    <b-col>
                      <b-row>
                        <label
                          class="d-flex justify-content-center align-items-center icon-card mr-2"
                          :style="isDarkSkin ? 'background: #392f28 !important' : 'background: #FFF8DD !important'"

                        >
                          <img
                            :src="
                              require('@/views/assistance/assets/clock.svg')
                            "
                            alt=""
                          />
                        </label>
                        <div
                          class="d-flex justify-content-center align-items-center mr-2"
                        >
                          <label style="color: #a09fa5" class="font-p"
                            >Start break</label
                          >
                        </div>
                        <div
                          class="d-flex justify-content-end align-items-center"
                        >
                          <label style="color: #0090e7" class="font-h">{{
                            parseInt(dailyMarkings.num_marks) < 2
                              ? null
                              : dailyMarkings.startBreak | myFullTime
                          }}</label>
                        </div>
                      </b-row>
                    </b-col>
                  </b-row>

                  <b-row>
                    <!-- end break -->
                    <b-col>
                      <b-row>
                        <label
                          class="d-flex justify-content-center align-items-center icon-card mr-2"
                          :style="isDarkSkin ? 'background: #392f28 !important' : 'background: #FFF8DD !important'"
                        >
                          <img
                            :src="
                              require('@/views/assistance/assets/clock.svg')
                            "
                            alt=""
                          />
                        </label>
                        <div
                          class="d-flex justify-content-center align-items-center mr-2"
                        >
                          <label style="color: #a09fa5" class="font-p"
                            >End break</label
                          >
                        </div>
                        <div
                          class="d-flex justify-content-end align-items-center"
                        >
                          <label style="color: #0090e7" class="font-h">{{
                            parseInt(dailyMarkings.num_marks) < 3
                              ? null
                              : dailyMarkings.endBreak | myFullTime
                          }}</label>
                        </div>
                      </b-row>
                    </b-col>
                    <!-- work end -->
                    <b-col>
                      <b-row>
                        <label
                          class="d-flex justify-content-center align-items-center icon-card mr-2"
                          :style="isDarkSkin ? 'background: #3a2434 !important' : 'background: #FFF5F8 !important'"
                        >
                          <img
                            :src="
                              require('@/views/assistance/assets/end-day.svg')
                            "
                            alt=""
                          />
                        </label>
                        <div
                          class="d-flex justify-content-center align-items-center mr-2"
                        >
                          <label style="color: #a09fa5" class="font-p"
                            >Work end</label
                          >
                        </div>
                        <div
                          class="d-flex justify-content-end align-items-center"
                        >
                          <label style="color: #0090e7" class="font-h">{{
                            parseInt(dailyMarkings.num_marks) < 4
                              ? null
                              : dailyMarkings.end | myFullTime
                          }}</label>
                        </div>
                      </b-row>
                    </b-col>
                  </b-row>
                </template>
              </b-col>
            </b-card>
        </b-col>

        <!-- separator -->
        <div class="miDiv" style="height: auto; border: 1.5px solid #0090e7; height: auto" />

        <!-- Current week section -->
        <b-col>
          <b-card>
            <h1 class="mb-2 target-title">Current week</h1>
            <p class="target-p mb-4">
              {{ currentDate }}
            </p>
            <b-row class="d-flex justify-content-around">
              <div class="d-flex justify-content-center align-items-center">
                <label
                  class="mr-1"
                  style="
                    width: 19px;
                    height: 19px;
                    background: #00f2c7;
                    border-radius: 5px;
                    font-family: 16px;
                    font-weight: 300;
                    line-height: 12px;
                  "
                /><label style="color: #a09fa5">Punctual</label>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <label
                  class="mr-1"
                  style="
                    width: 19px;
                    height: 19px;
                    background: #ffb826;
                    border-radius: 5px;
                  "
                /><label style="color: #a09fa5">Lateness</label>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <label
                  class="mr-1"
                  style="
                    width: 19px;
                    height: 19px;
                    background: #ff6d6d;
                    border-radius: 5px;
                  "
                /><label style="color: #a09fa5">Absence</label>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <label
                  class="mr-1"
                  style="
                    width: 19px;
                    height: 19px;
                    background: #0090e7;
                    border-radius: 5px;
                  "
                /><label style="color: #a09fa5">Day in progress</label>
              </div>
            </b-row>
            <b-row>
              <b-col>
                <b-card-text
                  class="mt-2 d-flex justify-content-md-center justify-content-around align-items-center flex-wrap"
                >
                  <div
                    v-for="(item, index) in itemsCurrentWeek"
                    :key="index"
                    class="text-center pl-1 pr-1 mb-1 mt-1"
                  >
                    <template>
                      <span
                        :id="`tooltip-button-variant-${index}`"
                        :style="
                          [
                            'HOLIDAYS',
                            'INCONSISTENCY',
                            'UNMARKED',
                            'ATTENDANCE',
                            'DELAY',
                            'MEDICAL REST',
                            'BIRTHAY',
                          ].includes(item.state)
                            ? optionsState[item.state]
                             : isDarkSkin ? 'background: #292C33 !important; color: #A09FA5 !important' : 'background: #F6F6FE !important; color: #18173D !important;'
                             
                        "
                        class="circle-assistance d-flex justify-content-center align-items-center"
                        @click="detailsMarks(item)"
                        style="width: 60px; height: 60px"
                      >
                        <label class="day-style">
                          {{ item.numberDay }}
                        </label>
                      </span>
                    </template>
                    <label
                      for=""
                      class="text-center day-title mt-2"
                      style="font-size: 13px"
                      :style="isDarkSkin ? 'color: #A09FA5': 'color: #18173D'"
                      ><b>{{ item.day.substring(0, 3) }}</b></label
                    >
                    <b-tooltip
                      :target="`tooltip-button-variant-${index}`"
                      placement="bottom"
                      >{{ item.state }}</b-tooltip
                    >
                  </div>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <hr style="border: 1.5px solid #0090e7" />


      <!-- Generated hours section -->
      <b-row>
        <b-col>
          <b-card>
            <b-row class="d-flex justify-content-center align-items-center">
              <b-col cols="12" md="2">
                <h1 class="mb-2 target-title">Generated hours</h1>
                <p class="target-p mb-4">
                  {{ currentDate }}
                </p>
                <b-col cols="">
                  <b-row>
                    <div
                      class="d-flex justify-content-center align-items-center mr-2"
                    >
                      <label
                        class="mr-1"
                        style="
                          width: 19px;
                          height: 19px;
                          background: #00f2c7;
                          border-radius: 5px;
                          font-size: 16px;
                          font-weight: 300;
                          line-height: 12px;
                          font-family: Montserrat;
                        "
                      ></label><label style="color: #a09fa5">Accumulated hours</label>
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <label
                        class="mr-1"
                        style="
                          width: 19px;
                          height: 19px;
                          background: #8bff6d;
                          border-radius: 5px;
                          font-size: 16px;
                          font-weight: 300;
                          line-height: 12px;
                          font-family: Montserrat;
                        "
                      ></label><label style="color: #a09fa5">Total overtime</label>
                    </div>
                  </b-row>
                </b-col>
              </b-col>

              <b-col cols="12" md="6">
                <!-- up -->
                <b-row class="mb-2">
                  <div
                    class="d-flex align-items-center flex-column" 
                  >
                      <span
                        class="mb-1 title-hours"
                        >End:</span>
 
                    <div
                      class="d-flex justify-content-center align-items-center b-hours"
                      :style="isDarkSkin ? '':'background: #F6F6FE; color: #18173D;'"
                    >
                      <label class="p-hours"
                        >{{
                          dailyMarkings.end | myFullTime
                        }}</label
                      >
                    </div>
                  </div>

                  <tabler-icon
                    class="mt-3 ml-1 mr-1"
                    icon="MinusIcon"
                    size="30"
                  />

                  <div
                    class="d-flex align-items-center flex-column">

                      <span
                        class="mb-1 title-hours"
                        >Start:</span>
 
                    <div
                      class="d-flex justify-content-center align-items-center b-hours"
                      :style="isDarkSkin ? '':'background: #F6F6FE; color: #18173D;'"
                    >
                      <label class="p-hours"
                        >{{
                          dailyMarkings.start | myFullTime
                        }}</label
                      >
                    </div>
                  </div>

                  <tabler-icon
                    class="mt-3 ml-1 mr-1"
                    icon="MinusIcon"
                    size="30"
                  />

                  <div class="d-flex align-items-center flex-column">
                      <span
                        class="mb-1 title-hours"
                        >Break:</span>
 
                    <div
                      class="d-flex justify-content-center align-items-center b-hours"
                      :style="isDarkSkin ? '':'background: #F6F6FE; color: #18173D;'"
                    >
                      <label class="p-hours"
                        >1h</label
                      >
                    </div>
                  </div>

                  <tabler-icon
                    class="mt-3 ml-1 mr-1"
                    icon="MenuIcon"
                    size="30"
                  />

                  <div
                    class="d-flex align-items-center flex-column" 
                  >
                      <span
                        class="mb-1 title-hours"
                        style="font-size: 14px"
                        >Accumulated hours</span>
 
                    <div
                      class="d-flex justify-content-center align-items-center b-hours"
                      style="background: #00F2C7"
                    >
                      <label class="p-hours" style="color: #18173D"
                        >{{
                          otherCalculations.totalHoursByDay
                        }}h</label
                      >
                    </div>
                  </div>
                </b-row>
                <!-- down -->
                <b-row>
                  <div
                    class="d-flex align-items-center flex-column"
                    
                  >

                      <span
                        class="mb-1 title-hours"
                        style="font-size: 14px"
                        >Accumulated hours</span>
 
                    <div
                      class="d-flex justify-content-center align-items-center b-hours"
                      :style="isDarkSkin ? '':'background: #F6F6FE; color: #18173D;'"
                    >
                      <label
                      class="p-hours"
                        >
                        {{
                          otherCalculations.totalHoursByDay
                        }}
                        </label
                      >
                    </div>
                  </div>

                  <tabler-icon
                    class="mt-3 ml-1 mr-1"
                    icon="MinusIcon"
                    size="30"
                  />

                  <div
                    class="d-flex align-items-center flex-column"
                    
                  >

                      <span
                        class="mb-1 title-hours"
                        >Works hours</span>
 
                    <div
                      class="d-flex justify-content-center align-items-center b-hours"
                      :style="isDarkSkin ? '':'background: #F6F6FE; color: #18173D;'"
                    >
                      <label
                      class="p-hours"
                        >{{ workingHoursByDay }}</label
                      >
                    </div>
                  </div>

                  <tabler-icon
                    class="mt-3 ml-1 mr-1"
                    icon="MenuIcon"
                    size="30"
                  />

                  <div class="d-flex align-items-center flex-column">

                      <span class="mb-1 title-hours">
                        Total overtime
                      </span>
 
                    <div
                      class="d-flex justify-content-center align-items-center b-hours"
                      style="background: #8BFF6D"
                    >
                      <label class="p-hours" style="color: #18173D"
                        >{{
                otherCalculations.overtimeByDay
              }}h</label
                      >
                    </div>
                  </div>

                  <div class="d-flex align-items-center flex-column"/>


                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <!-- modal details -->
    <modal-details-marks
      v-if="showDetailsMarks"
      :show="showDetailsMarks"
      :details="dataMarks"
      @hidden="hiddenDetailsMarks"
    />

    <!-- modal medical rest -->
    <modal-medical-rest
      v-if="showMedicalRest"
      :employee="employee"
      @closeModal="hiddenMedicalRest"
    />
    <modal-rejected-justifications
      v-if="openModalRejectedJustifications"
      :filters-date="filters"
      :employee-data="employee"
      @close="closeRejectedJustificationsModal"
    />
    <modal-hours-justified
      v-if="openModalHoursJustified"
      :filters-date="filters"
      :employee-data="employee"
      @close="closeHoursJustifiedModal"
    />
    <modal-payroll-payments
      v-if="openModalPayrollPayments"
      :user_id="employee.id"
      @closeModal="closeModalPayrollPayments"
    />
  </div>
</template>
<script>
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import serviceDashboard from "@/views/assistance/views/dashboard/services/dashboard.services.js";
import modalDetailsMarks from "@/views/assistance/views/dashboard/components/modalDetailsMarks.vue";
import modalMedicalRest from "@/views/assistance/views/dashboard/components/medicalRest/modalMedicalRest.vue";
import gridDetailsMarks from "@/views/assistance/views/dashboard/components/gridDetailsMarks.vue";
import gridMonthsMarks from "@/views/assistance/views/dashboard/components/gridMonthsMarks.vue";
import ModalRejectedJustifications from "@/views/assistance/views/dashboard/components/Overtime/modals/ModalRejectedJustifications.vue";
import ModalHoursJustified from "@/views/assistance/views/dashboard/components/Overtime/modals/ModalHoursJustified.vue";
import ModalPayrollPayments from "@/views/assistance/views/dashboard/components/modalPayrollPayments.vue";

import CustomizedRadioGroup from "@/commons/CustomizedRadioGroup.vue";

export default {
  components: {
    VueApexCharts,
    modalDetailsMarks,
    modalMedicalRest,
    gridDetailsMarks,
    gridMonthsMarks,
    ModalRejectedJustifications,
    ModalHoursJustified,
    ModalPayrollPayments,
    CustomizedRadioGroup,
  },
  props: {
    directToPayroll: {
      type: Number, 
      default: 0, 
    },
  },
  data() {
    return {
      // Password App
      dataEmployeeTable: null,
      filters: {
        filterDay: null,
        filterWeekStart: null,
        filterWeekEnd: null,
        filterMonth: null,
        filterYear: null,
      },
      keyGridMonth: 0,
      keyGrid: 0,
      keyChart: 0,
      employee: {
        id: 0,
        name: "",
      },
      firstFilter: {
        options: [
          {
            text: "Graphic",
            value: "graphic",
          },
          {
            text: "Report",
            value: "report",
          },
        ],
        value: "report",
      },
      secondFilter: {
        options: [
          {
            text: "Month",
            value: "month",
          },
          {
            text: "Week",
            value: "week",
          },
          {
            text: "Customized",
            value: "customized",
          },
        ],
        value: "week",
      },
      paramsWeek: {
        from: moment()
          .clone()
          .startOf("isoWeek")
          .isoWeekday(1)
          .format("YYYY-MM-DD"),
        to: moment()
          .clone()
          .endOf("isoWeek")
          .isoWeekday(7)
          .format("YYYY-MM-DD"),
      },
      disabledWeek: true,
      paramsMonth: {
        month: moment().format("MM"),
        year: moment().format("YYYY"),
      },
      optionMonths: [],
      optionsYear: [],
      dailyMarkings: {
        start: "09:00",
        end: "19:00",
        startBreak: "13:00",
        endBreak: "14:00",
        date_mark: moment().format("YYYY-MM-DD"),
      },
      otherCalculations: {
        totalHoursByDay: 0,
        overtimeByDay: 0,
        hoursWorkedByFilter: 0,
        accumulatedHoursByFilter: 0,
        overtimeByFilter: 0,
      },
      series: [
        {
          name: "Accumulated Hours",
          data: [],
        },
        {
          name: "Justified Hours",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 500,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          type: "date",
          categories: [],
          labels: {
            show: true,
            style: {
              colors: "#6e6b7b",
            },
          },
        },
        legend: {
          position: "top",
          offsetX: -20,
          labels: {
            colors: "#6e6b7b",
          },
        },
        fill: {
          opacity: 1,
        },
      },
      currentWeek: {
        start: moment().startOf("week").add(1, "days").format("YYYY-MM-DD"),
        end: moment().endOf("week").add(1, "days").format("YYYY-MM-DD"),
      },
      itemsCurrentWeek: [],
      attendanceStatus: {
        text: "",
        class: "",
      },
      dataMarks: {},
      showDetailsMarks: false,
      showMedicalRest: false,
      items: [],
      optionsState: {
        UNMARKED: "background-color:#FF6D6D;",
        FAULTS: "background-color:#fc424a;",
        PERMISSION: "border:1px solid #fc424a;",
        LATE: "background-color: #FFB826",
        "MEDICAL REST": "background-color:#85C1E9;",
        VACATIONS: "background-color:#F7DC6F;",
        DELAY: "border: 1px solid #EB984E !important;",
        REMOTE: "border: 1px solid #EB984E !important;",
        BIRTHAY: "border 1px solid #EB984E;",
        HOLIDAYS:
          "background-color:#8E44AD;color:white !important;font-size:12px !important;",
        "NOT REGISTER":
          "color:#18173D !important; background-color: #F6F6FE",
        "NO REGISTERED":
          "color:#18173D !important; background-color: #F6F6FE",
        ABSENCE: "background-color:#fc424a;",
        INCONSISTENCY: "background-color:#0090E7;",
        ATTENDANCE: "background-color:#00F2C7; color: #FFFFFF; ",
      },
      openModalRejectedJustifications: false,
      openModalHoursJustified: false,
      openModalPayrollPayments: false,
      user_id: 0,
      dayOffWeek: null,
    };
  },
  created() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "sticky" });
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
      type: "sticky",
    });
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
    this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical");
  },
  async mounted() {
    this.initChart();
    this.getMonthsToFilter();
    this.getYearsFilter();
    await this.getReportCurrentDay();
    await this.getReportCurrentWeek();

    this.employee.id = this.currentUser.user_id;
    this.employee.name = this.currentUser.fullName;
    this.chartOptions.xaxis.labels.style.colors = this.isDarkSkin
      ? "#ffffff"
      : "#000000";
    this.chartOptions.legend.labels.colors = this.isDarkSkin
      ? "#ffffff"
      : "#000000";
    await this.getEmployeeScheduleById();
    if(this.$route.params.directToPayroll == 1) {this.openModalPayrollPayments = true;}  },
  methods: {
    async getEmployeeScheduleById() {
      try {
        const params = {
          employee_id: this.dailyMarkings.employee_id,
          mark_time:
            this.dailyMarkings.date_mark && this.dailyMarkings.start
              ? moment(
                  `${this.dailyMarkings.date_mark} ${
                    this.dailyMarkings.start
                      ? this.dailyMarkings.start
                      : moment("HH:mm:ss")
                  }`
                ).format("YYYY-MM-DD HH:mm:ss")
              : null,
        };
        const { data } = await serviceDashboard.getEmployeesById(params);

        this.dayOffWeek = data.data[0].hours_worked;
      } catch (error) {
        this.showErrorSwal();
        console.log("error getEmployeesById", error);
      }
    },

    closeModalPayrollPayments() {
      this.openModalPayrollPayments = false;
    },
    openRejectedJustificationsModal() {
      this.employee.user_id = this.currentUser.user_id;
      this.openModalRejectedJustifications = true;
    },
    closeRejectedJustificationsModal() {
      this.openModalRejectedJustifications = false;
    },
    openHoursJustifiedModal() {
      this.employee.user_id = this.currentUser.user_id;
      this.openModalHoursJustified = true;
    },
    closeHoursJustifiedModal() {
      this.openModalHoursJustified = false;
    },
    hoursAccumulated(total) {
      this.otherCalculations.accumulatedHoursByFilter = total;
    },
    getMonthsToFilter(year) {
      this.optionMonths = [];
      let currentMonth = moment().format("MM");
      const currentYear = moment().format("YYYY");
      const monthFormat = "MMMM";
      const yearFormat = "YYYY";
      const currentYearFormated = moment(currentYear.toString(), "YYYY").format(
        yearFormat
      );
      const currentMonthFormated = moment(currentMonth.toString(), "MM").format(
        monthFormat
      );
      if (currentYearFormated != year && typeof year !== "undefined") {
        currentMonth = 12;
      }

      for (let month = 1; month <= currentMonth; month += 1) {
        const monthObject = {
          text: moment(month.toString(), "MM").format(monthFormat),
          value: month.toString().padStart(2, "0"),
        };
        this.optionMonths.push(monthObject);
      }
    },
    getYearsFilter() {
      const moment = require("moment");
      const currentYear = moment().format("YYYY");
      const yearFormat = "YYYY";

      for (let year = 2023; year <= currentYear; year += 1) {
        const yearObject = {
          text: moment(year.toString(), "YYYY").format(yearFormat),
          value: year.toString().padStart(2, "0"),
        };
        this.optionsYear.push(yearObject);
      }
    },
    initChart() {
      const options = this.chartOptions;
      options.chart.events = {
        dataPointMouseEnter: (
          event,
          chartContext,
          { seriesIndex, dataPointIndex }
        ) => {
          const tooltip = chartContext.el.querySelector(".apexcharts-tooltip");
          const tooltip_title = chartContext.el.querySelector(
            ".apexcharts-tooltip-title"
          );
          if (tooltip) {
            var color = this.isDarkSkin ? "#F7F7F7" : "#000000";
            var backgroundColor = this.isDarkSkin ? "#3A3A3A" : "#ffffff";
            tooltip.style.backgroundColor = backgroundColor;
            tooltip.style.color = color;
            tooltip.style.border = "1px solid #000000";
          }
          if (tooltip_title) {
            var color = this.isDarkSkin ? "#F7F7F7" : "#000000";
            var backgroundColor = this.isDarkSkin ? "#3A3A3A" : "#ffffff";
            tooltip_title.style.backgroundColor = backgroundColor;
            tooltip_title.style.color = color;
            tooltip_title.style.borderBottom = "1px solid #000000";
          }
        },
      };

      this.chart = new ApexCharts(this.$refs.chart, options);
    },
    async getReportCurrentDay() {
      try {
        const params = {
          user_id: this.currentUser.user_id,
          filterDay: moment().format("YYYY-MM-DD"),
          filterWeekStart: null,
          filterWeekEnd: null,
          filterMonth: null,
          filterYear: null,
        };
        const { data } = await serviceDashboard.getDetailsAttendance(params);

        this.dailyMarkings.start = data.data[0]?.first_mark;
        this.dailyMarkings.startBreak = data.data[0]?.second_mark;
        this.dailyMarkings.endBreak = data.data[0]?.third_mark;
        this.dailyMarkings.end = data.data[0]?.last_mark;
        this.otherCalculations.totalHoursByDay =
          data.data[0]?.working_hours_rounded;
        this.otherCalculations.overtimeByDay = data.data[0]?.overtime;
        this.dailyMarkings.num_marks = data.data[0]?.num_marks;
        this.dailyMarkings.employee_id = data.data[0]?.employee_id;
        this.dailyMarkings.date_mark = data.data[0]?.date_mark;
      } catch (error) {
        this.showErrorSwal();
        console.log("error dashboardUser", error);
      }
    },
    async getReportCurrentWeek() {
      try {
        const data = await this.getData(
          null,
          this.currentWeek.start,
          this.currentWeek.end,
          null,
          null
        );
        // generated days of current week
        this.itemsCurrentWeek = [];

        let miDay = moment(this.currentWeek.start).format("YYYY-MM-DD");
        const dayEnd = moment(this.currentWeek.end).format("YYYY-MM-DD");
        while (miDay <= dayEnd) {
          const item = data.find((item) => item.date_mark == miDay);

          this.itemsCurrentWeek.push({
            ...item,
            day: moment(miDay).format("dddd"),
            numberDay: moment(miDay).format("DD"),
            date_mark: miDay,
            num_marks: item ? item.num_marks : 0,
            first_mark: item?.first_mark,
            second_mark: item?.second_mark,
            third_mark: item?.third_mark,
            last_mark: item?.last_mark,
            working_hours: item ? item.working_hours : 0,
            working_hours_rounded: item ? item.working_hours_rounded : 0,
            working_mark_hours_valid: item ? item.working_mark_hours_valid : 0,
            overtime: item ? item.overtime : 0,
            overtime_approved: item ? item.overtime_approved : 0,
            state: item ? item.type : "NOT REGISTER",
            total_hours_validate: item ? item.total_hours_validate : 0,
          });
          miDay = moment(miDay).add(1, "days").format("YYYY-MM-DD");
        }

        // count of attendance this week
        let Absence = 0;
        let Inconsistencies = 0;
        let Attended = 0;
        this.itemsCurrentWeek.map((item) => {
          if (!item.state) {
            return;
          }
          if (["UNMARKED", "FAULTS"].includes(item.state)) {
            Absence++;
          } else if (["LATE", "DELAY", "INCONSISTENCY"].includes(item.state)) {
            Inconsistencies++;
          } else if (
            [
              "PERMISSION",
              "MEDICAL REST",
              "VACATIONS",
              "REMOTE",
              "BIRTHAY",
              "HOLIDAYS",
              "ATTENDANCE",
            ].includes(item.state)
          ) {
            Attended++;
          }
        });

        // state of attendance this week
        switch (true) {
          case Attended == 0 && Absence == 0 && Inconsistencies == 0:
            this.attendanceStatus.text = "Not register";
            this.attendanceStatus.class = "secondary";
            break;
          case Attended >= 6:
            this.attendanceStatus.text = "Excellent";
            this.attendanceStatus.class = "success";
            break;
          case Absence >= 6:
            this.attendanceStatus.text = "Deficient";
            this.attendanceStatus.class = "danger";
            break;
          case Attended > Inconsistencies && Attended > Absence:
            this.attendanceStatus.text = "Good";
            this.attendanceStatus.class = "primary";
            break;
          case Inconsistencies >= Attended && Inconsistencies >= Absence:
            this.attendanceStatus.text = "Regular";
            this.attendanceStatus.class = "info";
            break;
          case Absence >= Attended && Absence >= Inconsistencies:
            this.attendanceStatus.text = "Bad";
            this.attendanceStatus.class = "secondary";
            break;
        }
      } catch (error) {
        this.showErrorSwal();
        console.log("error dashboardUser", error);
      }
    },
    async getGraphics() {
      try {
        const filterWeekStart =
          this.secondFilter.value == "week" ||
          this.secondFilter.value == "customized"
            ? this.paramsWeek.from
            : null;
        const filterWeekEnd =
          this.secondFilter.value == "week" ||
          this.secondFilter.value == "customized"
            ? this.paramsWeek.to
            : null;
        const filterMonth =
          this.secondFilter.value == "month" ? this.paramsMonth.month : null;
        const filterYear =
          this.secondFilter.value == "month" ? this.paramsMonth.year : null;
        const data = await this.getData(
          null,
          filterWeekStart,
          filterWeekEnd,
          filterMonth,
          filterYear
        );
        this.items = data;
        this.generateValuesGraphics(data);
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getData(
      filterDay,
      filterWeekStart,
      filterWeekEnd,
      filterMonth,
      filterYear
    ) {
      try {
        const params = {
          user_id: this.currentUser.user_id,
          filterDay,
          filterWeekStart,
          filterWeekEnd,
          filterMonth,
          filterYear,
        };
        const { data } = await serviceDashboard.getDetailsAttendance(params);
        return data.data;
      } catch (error) {
        this.showErrorSwal();
        console.log(error);
      }
    },

    detailsMarks(item) {
      this.showDetailsMarks = true;
      this.dataMarks = item;
    },
    hiddenDetailsMarks() {
      this.showDetailsMarks = false;
    },
    hiddenMedicalRest() {
      this.showMedicalRest = false;
    },
    generateValuesGraphics(array) {
      if (
        this.secondFilter.value == "week" ||
        this.secondFilter.value == "customized"
      ) {
        this.series[0].data = array.map(
          (item) => item.working_mark_hours_valid
        );
        this.series[1].data = array.map((item) => item.overtime_approved);

        this.chartOptions.xaxis.categories =
          array.length > 0
            ? array.map((item) =>
                moment(item.date_mark).format("dddd, Do MMMM")
              )
            : [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ];
      } else {
        const dataDate = this.separeWeeksOfMonth(array);
        this.chartOptions.xaxis.categories = dataDate.optionsWeek;
        this.series[0].data = dataDate.accumulatedHours;
        this.series[1].data = dataDate.overtime;
      }

      this.totalAcumulatedHours();
      this.keyChart++;
    },
    separeWeeksOfMonth(array) {
      const diasEnMes = moment(
        `${this.paramsMonth.year}-${this.paramsMonth.month + 1}`,
        "YYYY-MM"
      ).daysInMonth();
      const semanas = [];
      const acumulatedHours = [];
      const overtime = [];
      let semanaActual = [];

      let valuesHours = 0;
      let valuesOvertime = 0;

      for (let dia = 1; dia <= diasEnMes; dia++) {
        const fecha = moment(
          `${this.paramsMonth.year}-${this.paramsMonth.month + 1}-${dia}`,
          "YYYY-MM-DD"
        );
        const numSemana = fecha.week();
        const item = array.find(
          (element) => element.date_mark == fecha.format("YYYY-MM-DD")
        );
        if (numSemana !== semanaActual) {
          semanaActual = numSemana;
          valuesHours = 0;
          valuesOvertime = 0;
          semanas.push([]);
          acumulatedHours.push([]);
          overtime.push([]);
        }

        // debugger;
        valuesHours += item ? parseInt(item.working_mark_hours_valid) : 0;
        valuesOvertime += item
          ? parseInt(item.overtime_approved) + parseInt(item.holidays)
          : 0;
        semanas[semanas.length - 1].push(fecha.format("YYYY-MM-DD"));
        acumulatedHours[acumulatedHours.length - 1].push(valuesHours);
        overtime[overtime.length - 1].push(valuesOvertime);
      }

      // return values (weeks) to graphics
      const optionsWeek = [];
      for (let i = 0; i < semanas.length; i++) {
        const firstDayWeek = semanas[i][0]; // omit sunday
        const lastDayWeek = semanas[i][semanas[i].length - 1];
        optionsWeek.push([firstDayWeek, lastDayWeek]);
      }
      // return values (working hours) to graphics
      const optionsValues = [];
      for (let i = 0; i < acumulatedHours.length; i++) {
        optionsValues.push(
          acumulatedHours[i][acumulatedHours[i].length - 1] > 46
            ? 46
            : acumulatedHours[i][acumulatedHours[i].length - 1]
        );
      }

      const optionsOvertime = [];
      for (let i = 0; i < overtime.length; i++) {
        optionsOvertime.push(overtime[i][overtime[i].length - 1]);
      }

      return {
        optionsWeek,
        accumulatedHours: optionsValues,
        overtime: optionsOvertime,
      };
    },
    async changeWeek(week) {
      this.paramsWeek.from = moment(this.paramsWeek.from)
        .add(week, "week")
        .format("YYYY-MM-DD 00:00:00");

      this.paramsWeek.to = moment(this.paramsWeek.from)
        .add(6, "days")
        .format("YYYY-MM-DD 00:00:00");

      this.disabledWeek = this.paramsWeek.to >= this.currentWeek.end;

      if (this.firstFilter.value == "graphic") {
        await this.getGraphics();
      } else if (this.secondFilter.value == "week") {
        this.keyGrid++;
      } else {
        this.keyGridMonth++;
      }
    },
    totalAcumulatedHours() {
      this.otherCalculations.accumulatedHoursByFilter = this.items.reduce(
        (a, b) => a + b.total_hours_validate,
        0
      );
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      skin: "appConfig/skin",
    }),
    currentDate() {
      return moment().format("ddd, D MMM YYYY");
      // return `${moment().format("dddd")} ${moment().format("MM-DD-YYYY")}`;
    },
    workingHoursByDay() {
      return this.dayOffWeek;
    },
    hoursWorkedByFilter() {
      if (this.secondFilter.value == "week") {
        // hours to be work by week
        return 46;
      }
      // hours to be work by month
      let hours = 0;
      const lastDayMonth = moment(
        `${this.paramsMonth.year}-${this.paramsMonth.month}`,
        "YYYY-MM-DD"
      ).daysInMonth();

      for (let index = 1; index <= lastDayMonth; index++) {
        const dateOfWeek = moment(
          `${this.paramsMonth.year}-${this.paramsMonth.month + 1}-${index}`,
          "YYYY-MM-DD"
        ).format("dddd");
        if (dateOfWeek.toLowerCase() == "saturday") {
          hours += 6;
        } else if (dateOfWeek.toLowerCase() != "sunday") {
          hours += 8;
        }
      }

      return hours;
    },
  },
  watch: {
    "firstFilter.value": async function () {
      if (this.firstFilter.value == "graphic") {
        await this.getGraphics();
      } else if (this.secondFilter.value == "week") {
        this.keyGrid++;
      } else {
        this.keyGridMonth++;
      }
    },
    "secondFilter.value": async function () {
      if (this.firstFilter.value == "graphic") {
        await this.getGraphics();
      } else if (this.secondFilter.value == "week") {
        this.keyGrid++;

        // set default current week
        this.paramsWeek.from = moment()
          .clone()
          .startOf("isoWeek")
          .isoWeekday(1)
          .format("YYYY-MM-DD");
        this.paramsWeek.to = moment()
          .clone()
          .endOf("isoWeek")
          .isoWeekday(7)
          .format("YYYY-MM-DD");
      } else {
        this.keyGridMonth++;
      }
    },
    "paramsWeek.from": async function () {
      if (this.secondFilter.value == "customized") {
        const dateParts = this.paramsWeek.from.split("/"); // Dividir la cadena en partes
        const formattedDate = `${dateParts[2]}-${dateParts[0].padStart(
          2,
          "0"
        )}-${dateParts[1].padStart(2, "0")}`;
        this.paramsWeek.from = formattedDate;
      }
      if (this.firstFilter.value == "graphic") {
        if (this.secondFilter.value != "week") {
          await this.getGraphics();
        }
      } else if (
        this.secondFilter.value == "week" ||
        this.secondFilter.value == "customized"
      ) {
        this.keyGrid++;
      } else {
        this.keyGridMonth++;
      }
    },
    "paramsWeek.to": async function () {
      if (this.secondFilter.value == "customized") {
        const dateParts = this.paramsWeek.to.split("/"); // Dividir la cadena en partes
        const formattedDate = `${dateParts[2]}-${dateParts[0].padStart(
          2,
          "0"
        )}-${dateParts[1].padStart(2, "0")}`;
        this.paramsWeek.to = formattedDate;
      }
      if (this.firstFilter.value == "graphic") {
        if (this.secondFilter.value != "week") {
          await this.getGraphics();
        }
      } else if (
        this.secondFilter.value == "week" ||
        this.secondFilter.value == "customized"
      ) {
        this.keyGrid++;
      } else {
        this.keyGridMonth++;
      }
    },
    "paramsMonth.month": async function () {
      if (this.firstFilter.value == "graphic") {
        await this.getGraphics();
      } else if (this.secondFilter.value == "week") {
        this.keyGrid++;
      } else {
        this.keyGridMonth++;
      }
    },
    "paramsMonth.year": async function () {
      this.getMonthsToFilter(this.paramsMonth.year);
      if (this.firstFilter.value == "graphic") {
        await this.getGraphics();
      } else if (this.secondFilter.value == "week") {
        this.keyGrid++;
      } else {
        this.keyGridMonth++;
      }
    },

    skin(oldVal, newVal) {
      if (newVal == "light") {
        this.chartOptions.xaxis.labels.style.colors = "#d8d8d8";
        this.chartOptions.legend.labels.colors = "#d8d8d8";
      } else {
        this.chartOptions.xaxis.labels.style.colors = "#6e6b7b";
        this.chartOptions.legend.labels.colors = "#6e6b7b";
      }
      this.keyChart++;
    },
  },
  filters: {
    myFullTime(value) {
      return value ? moment(value, "HH:mm").format("HH:mm") : "--:--";
    },
  },
};
</script>
<style scoped>
.circle-assistance,
.circle-assistance-not-hover {
  margin: 0px auto;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  width: 20px;
  height: 20px;
}
.circle-assistance:hover {
  transition: 200ms ease-in-out;
  transform: scale(1.2);
}

.text-goals {
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 400;
  line-height: 14px;
  color: #a09fa5;
}

.border-radius-right {
  background: #0090e7 !important;
  border: none;
  -webkit-border-radius: 7px;
  -webkit-border-top-left-radius: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius: 7px;
  -moz-border-radius-topleft: 0px;
  -moz-border-radius-bottomleft: 0px;
  border-radius: 7px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.border-radius-left {
  background: #0090e7 !important;
  border: none;
  -webkit-border-radius: 0px;
  -webkit-border-top-left-radius: 7px;
  -webkit-border-bottom-left-radius: 7px;
  -moz-border-radius: 0px;
  -moz-border-radius-topleft: 7px;
  -moz-border-radius-bottomleft: 7px;
  border-radius: 0px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.text-montserrat {
  font-family: "Montserrat";
}

.select-dark {
  border-style: hidden !important;
  width: 153px !important;
  height: 44px !important;
  border-radius: 7px !important;
  font-size: 16px !important;
  color: #a09fa5;
}

.target-title {
  font-family: Rubik;
  font-weight: 600;
  font-size: 28px;
  line-height: 20px;
}

.target-p {
  font-family: Rubik;
  font-weight: 400;
  font-size: 23px;
  line-height: 16px;
}

.icon-card {
  height: 55px;
  width: 55px;
  border-radius: 8px;
}

.font-p {
  font-family: Montserrat;
  font-size: 19px;
  line-height: 16.5px;
  font-weight: 500px;
}

.font-h {
  font-family: Montserrat;
  font-size: 25px;
  line-height: 18px;
  font-weight: 500px;
}

.day-style {
  font-family: Rubik;
  font-size: 26px;
  line-height: 18px;
  font-weight: 500 !important;
}

.day-title {
  font-family: Montserrat;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
}

.title-hours{

  font-family: Montserrat;
  font-size: 19px;
  font-weight: 500;
  line-height: 14px;
}

.b-hours{
  width: 169px;
  height: 55px;
  background: #292c33;
  border-radius: 8px;
}

.p-hours{
  font-family: Montserrat;
  font-weight: 600;
  font-size: 32px;
}

.datepicker{
  background: white !important;
}

.customized-button span {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 18px;
  text-align: center;
}
.customized-button {
  border: 0px !important;
  border-radius: 7px !important;
}

.option-customized-dark{
  background: black;
}

.option-customized{
  background: white;
}


@media (max-width: 768px) {
  /* Add your mobile-specific styles here */
  .text-goals {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }

  .dataStyle{
    font-size: 18px !important;
  }

  .btn-text{
    font-size: 10px !important;
  }

  .customized-button{
    flex-grow: 1;
    margin-bottom: 10px;
  }

  .miDiv {
    display: none;
    /* width: auto; */
  }
  
  /* You can adjust other styles as needed for mobile devices */
}

</style>
