<template>
  <b-modal
    ref="modal-approve-payroll-payments"
    title="Approve Payroll Payment"
    size="sm"
    hide-footer
    @hidden="close"
  >
    <b-form>
      <b-col sm="12">
        <validation-provider
          v-slot="{ errors }"
          name="file-pdf"
          rules="required"
        >
          <b-form-group
            label="Upload Signature"
            class="w-100"
            :state="errors[0] ? false : null"
          >
            <b-form-file
              v-model="fileToUploadPdf"
              :class="errors[0] ? 'border-danger' : ''"
              accept="image/jpeg, image/png"
              @input="fillFileData"
            />

            <span
              v-if="errors[0]"
              class="text-danger"
            >
              File {{ errors[0] }}</span>
            <b-button
              variant="primary"
              style="margin-top: 10px"
              @click="uploadSignature"
            >Approve Payroll</b-button>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-form>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import payrollPaymentsService from '@/views/commons/components/payroll-payments/payroll-payments.service.js';

export default {
  props: {
    payrollPaymentId: {
      type: Number,
      required: true,
    },
    payroll: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fileToUploadPdf: [],

      user_id: '',
      file: {
        signature: '',
        name: '',
        size: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

  },
  mounted() {
    this.toggleModal('modal-approve-payroll-payments');
    this.fileToUploadPdf = null;
  },
  methods: {
    addFile() {
      this.$refs.refInputEl.$el.childNodes[0].click();
    },
    close() {
      this.$emit('closeModal', null);
    },
    async fillFileData(event) {

      this.file.name = this.fileToUploadPdf.name;
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUploadPdf);
      reader.onload = async file => {
        this.file.signature = file.target.result;
      };
    },
    async uploadSignature() {

      if (!this.fileToUploadPdf) {
        this.showWarningSwal('Please select a file to upload.');
        return;
      }

      try {
        const formData = new FormData();
        formData.append('user_id', this.currentUser.user_id);
        formData.append('file', this.fileToUploadPdf, this.fileToUploadPdf.name);
        formData.append('name', this.fileToUploadPdf.name);
        formData.append('payroll_payment_id', this.payrollPaymentId);
        this.addPreloader();
        const data = await payrollPaymentsService.approvePayrollPayment(formData);

        // Cerrar el modal después de completar la operación
        // También puedes emitir un evento aquí si necesitas que el componente padre se entere de que se aprobó el pago.
        // this.$emit("payrollPaymentApproved", params);

        // Restablecer los datos del archivo
        if (data.status == 200) {
          this.removePreloader();

          this.showSuccessSwal(data.message);
          this.$emit('closeModal',this.file.name);
        }
        this.fileToUploadPdf = null;
        this.file= {
          signature: '',
          name: '',
          size: '',
        };

        // Otras acciones que necesites después de completar la aprobación
        // ...
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal('Error al subir el archivo');
        console.error('Error al subir el archivo:', error);
      }
    },
  },
};
</script>
