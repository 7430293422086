/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class PayrollPaymentsService {
  // eslint-disable-next-line class-methods-use-this
  async getPayrollPaymentsEmployees(body) {
    try {
      const data = await amgApi.post(
        'payroll-payments/get-payroll-payments-employees',
        body,
      );
      return data;
    } catch (error) {
      console.log('Something went wrong on getPayrollPayments:', error);
      throw error;
    }
  }

  async generatePayrollPaymentAllEmployees(body) {
    try {
      const data = await amgApi.post(
        '/payroll-payments/generate-payroll',
        body,
      );
      return data;
    } catch (error) {
      console.error(
        'Something went wrong on generate-payroll-payment-all-employees',
        error,
      );
    }
    return [];
  }

  async sendEmailPayrollPayments(body) {
    try {
      const data = await amgApi.post(
        '/payroll-payments/send-email-specific-employees',
        body,
      );
      return data;
    } catch (error) {
      console.error(
        'Something went wrong on generate-payroll-payment-all-employees',
        error,
      );
    }
  }

  async existPayrollPaymentEmployees(body) {
    try {
      const data = await amgApi.post(
        '/payroll-payments/exist-payroll-payment-employees',
        body,
      );
      return data;
    } catch (error) {
      console.error(
        'Something went wrong on exist-payroll-payment-employees',
        error,
      );
    }
  }

  async listEmployees(body) {
    try {
      const data = await amgApi.post(
        '/payroll-payments/get-employees-with-payroll',
        body,
      );
      return data;
    } catch (error) {
      console.error('Something went wrong on get employees', error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async getPayrollPaymentGeneral(body) {
    try {
      const data = await amgApi.post('payroll-payments/return-payroll', body, {
        responseType: 'blob',
      });
      return data;
    } catch (error) {
      console.log('Something went wrong on getPayrollPaymentGeneral:', error);
      throw error;
    }
  }

  async getPayrollPaymentsReport(body) {
    try {
      const data = await amgApi.post('payroll-payments/get-payroll-payments-report', body);
      return data;
    } catch (error) {
      console.log('Something went wrong on getPayrollPaymentsReport:', error);
      throw error;
    }
  }

  async getActiveCompanies(body) {
    try {
      const data = await amgApi.get('payroll-payments/get-active-companies', body);
      return data;
    } catch (error) {
      console.log('Something went wrong on getPayrollPaymentsReport:', error);
      throw error;
    }
  }

  async getPdfPayrollPayment(body) {
    try {
      const data = await amgApi.post(
        '/payroll-payments/generate-pdf-payroll-payment',
        body,
        { responseType: 'blob' },
      );
      return data;
    } catch (error) {
      console.error(
        'Something went wrong on generate-pdf-payroll-payment',
        error,
      );
    }
    return [];
  }

  async approvePayrollPayment(body) {
    try {
      const data = await amgApi.post(
        '/payroll-payments/approve-payroll-payment',
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return data;
    } catch (error) {
      console.error(
        'Something went wrong on approve-payroll-payment',
        error,
      );
    }
  }

  async regeneratePayrollPayment(body) {
    try {
      const data = await amgApi.post(
        '/payroll-payments/regenerate-pdf-payroll-payment',
        body,
        { responseType: 'blob' },
      );
      return data;
    } catch (error) {
      console.error(
        'Something went wrong on regenerate-pdf-payroll-payment',
        error,
      );
    }
  }

  async getDepartments(body) {
    try {
      const data = await amgApi.post(
        '/payroll-payments/get-departments',
        body,
      );
      return data;
    } catch (error) {
      console.error(
        'Something went wrong on get-departments',
        error,
      );
    }
  }
  async sendPayrollToEmployees(body) {
    try {
      const data = await amgApi.post(
        '/payroll-payments/send-payroll-to-employees',
        body,
      );
      return data;
    } catch (error) {
      console.error(
        'Something went wrong on send-payroll-to-employees',
        error,
      );
    }
  }

  async updateFifthCategoryCalculation(body) {
    try {
      const data = await amgApi.post(
        '/payroll-payments/update-fifth-category-calculation',
        body,
      );
      return data;
    } catch (error) {
      console.error(
        'Something went wrong on update-fifth-category-calculation',
        error,
      );
    }
  }

  async generateDetailPayrollExcel(body) {
    try {
      const data = await amgApi.post(
        'payroll-payments/generate-detail-payroll-excel',
        body,  { responseType: 'blob' },
      );
      return data;
    } catch (error) {
      console.log('Something went wrong on getPayrollPayments:', error);
      throw error;
    }
  }
}

export default new PayrollPaymentsService();
