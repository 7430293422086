<template>
  <div>
    <b-modal
      v-model="oncontrol"
      size="lg"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="ligth"
      body-text-variant="dark"
      no-close-on-backdrop
      footer-bg-variant="ligth"
      footer-text-variant="dark"
      class="border-0 border-white"
      hide-footer
      @hidden="closeModal"
    >
      <template #modal-title>
        Payroll Payments
      </template>
      <b-container
        fluid
        class="p-1"
      >
        <b-row class="mt-2">
          <b-col cols="12">
            <filter-slot
              :filter="filteredFilters"
              :filter-principal="filterPrincipal"
              :no-visible-principal-filter="true"
              :total-rows="totalRows"
              :paginate="paginate"
              :start-page="startPage"
              :to-page="toPage"
              :send-multiple-sms="false"
              :change-columns-by-client="true"
              @reload="refreshTable()"
            >
              <b-table
                slot="table"
                ref="refPayrollPayments"
                no-provider-filtering
                :items="getPayrollsPaymentsProvider"
                :fields="filteredFields"
                primary-key="id"
                table-class="text-nowrap"
                show-empty
                sticky-header="50vh"
                :busy.sync="isBusy"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :current-page="paginate.currentPage"
                :per-page="paginate.perPage"
                :filter="searchInput"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading ...</strong>
                  </div>
                </template>
                <template #cell(actions)="data">
                  <feather-icon
                    v-if="
                      data.item.file_approved_route == null &&
                        data.item.is_sended == 1 &&
                        data.item.signature_route != null
                    "
                    :id="`${data.item.id}_tooltip_approve_payroll`"
                    icon="CheckIcon"
                    class="text-success mr-1"
                    style="cursor: pointer"
                    @click="generatePDFPayrollPayment(data.item, true,true)"
                  />
                  <b-tooltip
                    variant="success"
                    triggers="hover"
                    :target="`${data.item.id}_tooltip_approve_payroll`"
                    title="Approve Payroll"
                    :delay="{ show: 100, hide: 50 }"
                    placement="right"
                  />
                </template>
                <template #cell(payment_period)="data">
                  <feather-icon
                    icon="CalendarIcon"
                    class="text-success mr-1"
                  />
                  {{ `${data.item.month} - ${data.item.year}` }}
                </template>
                <template #cell(file)="data">
                  <a
                    v-if="data.item.file_route && data.item.is_sended == 1"
                    :id="`${data.item.id}_tooltip`"
                    :href="data.item.file_route"
                    style="cursor: pointer"
                    target="_blank"
                  >
                    <tabler-icon
                      size="20"
                      icon="FileDownloadIcon"
                      class="text-success"
                    />
                  </a>
                  <tabler-icon
                    v-else-if="
                      !data.item.file_route && data.item.is_sended == 1
                    "
                    :id="`${data.item.id}_tooltip`"
                    size="20"
                    icon="FileDownloadIcon"
                    class="text-warning cursor-pointer"
                    style="cursor: pointer"
                    @click="generatePDFPayrollPayment(data.item)"
                  />
                  <b-tooltip
                    :variant="data.item.file_route ? 'success' : 'warning'"
                    triggers="hover"
                    :target="`${data.item.id}_tooltip`"
                    :title="
                      data.item.file_route
                        ? 'Download payroll'
                        : 'Generate payroll'
                    "
                    :delay="{ show: 100, hide: 50 }"
                    placement="left"
                  />
                  <feather-icon
                    v-if="
                      data.item.file_route &&
                        !data.item.signature_route &&
                        data.item.is_sended == 1
                    "
                    :id="`${data.item.id}_tooltip_approve`"
                    size="20"
                    icon="MailIcon"
                    class="text-primary cursor-pointer"
                    style="cursor: pointer"
                    @click="openApprovePayrollPayments(data.item.id, data.item)"
                  />
                  <b-tooltip
                    :variant="
                      !data.item.signature_route ? 'primary' : 'warning'
                    "
                    triggers="hover"
                    :target="`${data.item.id}_tooltip_approve`"
                    title="Upload Signature "
                    :delay="{ show: 100, hide: 50 }"
                    placement="right"
                  />
                  <a
                    v-if="
                      data.item.signature_route &&
                        data.item.file_approved_route &&
                        data.item.is_sended == 1
                    "
                    target="_blank"
                    :href="data.item.file_approved_route"
                    style="cursor: pointer"
                  >
                    <tabler-icon
                      :id="`${data.item.id}_tooltip_approved`"
                      size="20"
                      icon="FileDownloadIcon"
                      class="text-primary"
                      target="_blank"
                    />
                  </a>
                  <!-- <tabler-icon
                    v-else-if="data.item.signature_route!=null && data.item.file_approved_route==null && data.item.is_sended==1"
                    :id="`${data.item.id}_tooltip_approved`"
                    size="20"
                    icon="FileDownloadIcon"
                    class="text-warning cursor-pointer"
                    @click="generatePDFPayrollPayment(data.item)"
                  /> -->
                  <b-tooltip
                    :variant="
                      data.item.file_approved_route ? 'primary' : 'warning'
                    "
                    triggers="hover"
                    :target="`${data.item.id}_tooltip_approved`"
                    :title="
                      data.item.file_approved_route
                        ? 'Download Approved Payroll'
                        : 'Generate Approved Payroll'
                    "
                    :delay="{ show: 100, hide: 50 }"
                    placement="right"
                  />
                </template>
              </b-table>
            </filter-slot>
          </b-col>
        </b-row>
      </b-container>
      <modal-approved-payroll-payments
        v-if="showModalApprovedPayrollPayments"
        :payroll-payment-id="payrollId"
        :payroll="payroll"
        @closeModal="(file) => closeModalApprovedPayrollPayments(file)"
      />
    </b-modal>
  </div>
</template>
<script>
import serviceDashboard from '@/views/assistance/views/dashboard/services/dashboard.services';
import { mapGetters } from 'vuex';
import ModalApprovedPayrollPayments from '@/views/commons/components/payroll-payments/modals/ModalApprovePayrollPayments.vue';

export default {
  components: {
    ModalApprovedPayrollPayments,
  },
  props: {
    user_id: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  data() {
    return {
      totalRows: 0,
      startPage: 0,
      toPage: 0,
      oncontrol: false,
      filteredFilters: [
        {
          type: 'select',
          margin: true,
          showLabel: true,
          label: 'Month',
          model: null,
          options: [
            { value: null, label: 'All' },
            { value: 1, label: 'January' },
            { value: 2, label: 'February' },
            { value: 3, label: 'March' },
            { value: 4, label: 'April' },
            { value: 5, label: 'May' },
            { value: 6, label: 'June' },
            { value: 7, label: 'July' },
            { value: 8, label: 'August' },
            { value: 9, label: 'September' },
            { value: 10, label: 'October' },
            { value: 11, label: 'November' },
            { value: 12, label: 'December' },
          ],
          reduce: 'value',
          selectText: 'label',
          cols: 6,
          visible: true,
        },
        {
          type: 'select',
          margin: true,
          showLabel: true,
          label: 'Year',
          model: null,
          options: [],
          reduce: 'value',
          selectText: 'label',
          cols: 6,
          visible: true,
        },
      ],
      filteredFields: [
        {
          key: 'payment_period',
          label: 'Payment period',
        },
        {
          key: 'file',
          label: 'File',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'created_by_name',
          label: 'Created by',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'actions',
          label: 'Actions',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      isBusy: false,
      sortBy: 'payment_date',
      sortDesc: false,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      searchInput: '',
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        label: 'Search',
        placeholder: 'Search ...',
        model: null,
      },
      showModalApprovedPayrollPayments: false,
      payrollId: null,
      payroll: null,
    };
  },
  mounted() {
    this.oncontrol = true;
    this.getYears();
  },
  methods: {
    closeModal() {
      this.oncontrol = false;
      this.$emit('closeModal');
    },
    getYears() {
      const currentYear = new Date().getFullYear();
      const years = [];
      years.push({ value: null, label: 'All' });
      for (let i = 2023; i <= currentYear; i += 1) {
        years.push({ value: i, label: i });
      }
      this.filteredFilters[1].model = currentYear;
      this.filteredFilters[1].options = years;
      return years;
    },
    refreshTable() {
      this.$refs.refPayrollPayments.refresh();
    },
    async closeModalApprovedPayrollPayments(file) {
      this.showModalApprovedPayrollPayments = false;
      if (file) {
        await this.generatePDFPayrollPayment(this.payroll, true);
      } else {
        return;
      }

    },
    async getPayrollsPaymentsProvider() {
      try {
        this.isBusy = true;
        const params = {
          employee_id: this.user_id,
          month: this.filteredFilters[0].model,
          year: this.filteredFilters[1].model,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
        };
        const { data } = await serviceDashboard.listPayrollPaymentEmployee(
          params,
        );
        this.totalRows = data.total || 0;
        this.startPage = data.from || 0;
        this.toPage = data.to || 0;
        this.paginate.currentPage = data.current_page || 0;
        this.paginate.perPage = data.per_page || 0;
        this.isBusy = false;
        return data.data;
      } catch (error) {
        this.showErrorSwal();
        this.isBusy = false;
        console.log(error);
      }
      return [];
    },
    async generatePDFPayrollPayment({ id, month, year }, status, approved) {
      try {
        if (!status) {
          const { isConfirmed } = await this.showConfirmSwal();

          if (!isConfirmed) return;
        }
        if (approved) {
          const { isConfirmed } = await this.showConfirmSwal('Are you sure you want to approve this payroll?');
          if (!isConfirmed) return;
        }
        this.addPreloader();
        // Helper function to download a blob
        const downloadBlob = (blob, fileName) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        };

        const response = await serviceDashboard.getPdfPayrollPayment({
          payroll_id: id,
          status,
        });
        const fileName = `BOLETA_DE_PAGO_${this.currentUser.fullName}_${month}_${year}.pdf`;

        // Convert the response data to a blob and download it
        const blob = new Blob([response.data], { type: 'application/pdf' });
        downloadBlob(blob, fileName);

        this.removePreloader();
        this.refreshTable();
      } catch (error) {
        console.log(error);
        this.removePreloader();
        this.showErrorSwal();
      }
    },
    openApprovePayrollPayments(itemid, item) {
      this.payrollId = itemid;
      this.payroll = item;
      this.showModalApprovedPayrollPayments = true;
    },
  },
};
</script>
